import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chevron } from '@jutro/components';
import styles from './VehicleHeaderComponent.module.scss';

class VehicleHeaderComponent extends Component {
    /**
     * @memberof gw-components-platform-react.VehicleHeaderComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.VehicleName - name of Vehicle
     * @prop {string} propTypes.path - path of Vehicle
     * @prop {func} propTypes.onEditVehicle - callback to edit Vehicle
     * @prop {bool} propTypes.isAccordionOpen - is accordion open
     * @prop {bool} propTypes.isEditable - is it editable
     * @prop {func} propTypes.onRemoveVehicle - remove Vehicle callback
     */

    static propTypes = {
        vehicleName: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        isAccordionOpen: PropTypes.bool,
        isEditable: PropTypes.bool,
        vehicleIndex: PropTypes.number,
        isPrefill: PropTypes.bool,
        isSelected: PropTypes.bool,
        onRemoveVehicle: PropTypes.func,
        onEditVehicle: PropTypes.func,
        closeOtherTiles: PropTypes.func
    };

    static defaultProps = {
        isAccordionOpen: false,
        isEditable: true,
        vehicleIndex: undefined,
        isPrefill: false,
        isSelected: false,
        onRemoveVehicle: undefined,
        onEditVehicle: undefined,
        closeOtherTiles: undefined
    };

    removeVehicle = () => {
        const { onRemoveVehicle, path } = this.props;
        onRemoveVehicle(path);
    };

    editVehicle = () => {
        const { onEditVehicle, vehicleIndex, isAccordionOpen, path } = this.props;
        onEditVehicle(path, vehicleIndex, isAccordionOpen);
    };

    closeAllTiles = () => {
        const { closeOtherTiles, vehicleIndex, path } = this.props;
        closeOtherTiles(path, vehicleIndex);
    };

    render() {
        const {
            vehicleName,
            isAccordionOpen,
            isEditable,
            vehicleIndex,
            isPrefill,
            isSelected
        } = this.props;

        return (
            <div className={styles.VehicleHeaderContainer} id={vehicleIndex} onClick={this.closeAllTiles}>
                <div className={styles.VehicleDetails}>
                    <div>
                        {/* <Chevron isOpen={isAccordionOpen} className={styles.chevron} /> */}
                        {(isSelected || (!isSelected && !isPrefill)) ? (
                            <div className={styles.selectedVehicleHeader}>
                                <div className={styles.selectedVehicleHeaderTitle}>
                                    {isSelected ? (
                                        <div className={styles.checkMark}>
                                            <i className="fa fa-check" aria-hidden="true" role="img" aria-label="Vehicle Added" title="Vehicle Added" />
                                        </div>
                                    )
                                        : <i className="fa fa-plus" aria-hidden="true" role="img" aria-label="Add Vehicle" title="Add Vehicle" />
                                    }
                                    <div className={styles.vehicleHeadingColumn}>
                                        <span className={styles.selectedVehicleName}>
                                            {vehicleName}
                                        </span>
                                    </div>
                                </div>
                                {isSelected ? (
                                    <div className={styles.editRemoveLink}>
                                        <a onClick={this.editVehicle}>
                                            edit
                                        </a>
                                        |
                                        <a onClick={this.removeVehicle}>
                                            remove
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                        )
                            : (
                                <div className={styles.vehicleHeader}>
                                    <div className={styles.vehicleHeaderTitle}>
                                        <i className="fa fa-plus" aria-hidden="true" role="img" aria-label="Add Vehicle" title="Add Vehicle" />
                                        <div className={styles.vehicleHeadingColumn}>
                                            <span>
                                                {vehicleName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default VehicleHeaderComponent;
