import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { getConfigValue } from '@jutro/config';
import { Tooltip, IconButton, Loader, ModalService } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { FormattedMessage } from 'react-intl';
// import { MockUpUtil } from 'gw-portals-util-js';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { PrefillLookupService, AddCoverablesService } from 'gw-capability-policycommon-alfa';
import VehicleHeaderComponent from '../../components/VehicleHeaderComponent/VehicleHeaderComponent';
import ErrorHandlingUtil from '../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';
import styles from './VehiclesPage.module.scss';
import cookie from 'js-cookie';

function YourVehicleTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const getFormattedMessage = () => {
        return (
            <FormattedMessage
                {...messages.mobileVehicleTooltip}
                values={{
                    mobile:
                        (<a href="tel:+1-800-964-2532" className="gw-contactNumber">
                            1-800-964-2532
                        </a>
                        )
                }}
            />
        );
    };
    let message = getFormattedMessage();
    let direction = 'top';
    if (breakpoint === 'desktop') {
        direction = 'right';
        message = messages.vehicleTooltip;
    }
    if (messageType.id === 'tooltipBusiness') {
        message = messages.vehicleBusinessTooltip;
        direction = 'top';
    }
    return (
        <Tooltip
            animation="fade"
            content={translator(message)}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            placement={direction}
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            {messageType.id === 'tooltip' ? (
                <IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} aria-hidden="true" aria-label="Information" />
            )
                : <span className={styles.vehicleCaptionSpan}>business</span>
            }
        </Tooltip>
    );
}

function VehiclesPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const [isPageInitialised, updateIsPageInitialised] = useState(false);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [hasInforceCurrentCarrier, updateHasInforceCurrentCarrier] = useState(false);
    const [nextErrorMessage, updateNextErrorMessage] = useState(false);
    const [hasPrefillVehicles, updateHasPrefillVehicles] = useState(false);
    const [selectedVehicleCount, updateSelectedVehicleCount] = useState(0);
    const [nextStep, setNextStep] = useState('Next: Drivers');
    const [showError, updateShowError] = useState(false);
    const [openIndex, updateOpenIndex] = useState(undefined);
    const [isLoading, updateIsLoading] = useState(false);
    const [dirtyFlag, updateDirtyFlag] = useState(false);
    const [dummyFlag, setDummyFlag] = useState(1);
    const [garrageAddress, updateGarageAddress] = useState({});
    const [vinNumber, updateVinNumber] = useState('');
    const [isVehiclePrimarilyKeptAtAddress, updateIsVehiclePrimarilyKeptAtAddress] = useState(false);

    const [lease, updateLease] = useState(false);


    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('VehiclePage');

    const checkSelectedVehicle = () => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, vehiclesPath);
        const vehicle = _.find(vehicles.children, (v) => {
            return v.isSelectedVehicle.value === true;
        });
        if (vehicle !== null && vehicle !== undefined) {
            return true;
        }
        return false;
    };


    const checkVehicleSelected = (prefillVehicle) => {
        let result = false;
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);

        if (vehicles !== undefined && vehicles.length === 0) {
            return result;
        }
        _.forEach(vehicles, (vehicle) => {
            // eslint-disable-next-line max-len
            if (!_.isEmpty(vehicle.vin) && !_.isEmpty(prefillVehicle.vin) && vehicle.vin === prefillVehicle.vin) {
                result = true;
            }
        });
        return result;
    };

    const getPrefillVehicleCount = () => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);
        const prefillVehicle = _.find(vehicles, (v) => {
            return (!v.isSelectedVehicle && v.isPrefillVehicle);
        });
        return (prefillVehicle !== null && prefillVehicle !== undefined);
    };


    const addVehicles = useCallback((prefillVehicles) => {
        const accountHolder = submissionVM.baseData.accountHolder.value;
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);
        if (typeof prefillVehicles.vehicles !== 'undefined' && prefillVehicles.vehicles.length > 0) {
            _.forEach(prefillVehicles.vehicles, (vehicle) => {
                if (!checkVehicleSelected(vehicle)) {
                    // eslint-disable-next-line max-len
                    const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto.coverables.vehicles;
                    const vehicleObj = {
                        costNew: {},
                        garagingAddress: {},
                        isOpen: false
                    };
                    const prefillVehicle = viewModelService.create(vehicleObj, _xCenter, _dtoName);
                    prefillVehicle.vin = vehicle.vin.toUpperCase();
                    prefillVehicle.year = vehicle.modelYear;
                    prefillVehicle.make = vehicle.make;
                    prefillVehicle.model = vehicle.model;
                    prefillVehicle.bodyType = vehicle.bodyType;
                    prefillVehicle.vehicleTrim = vehicle.trim;
                    prefillVehicle.vehicleType = 'PU';
                    prefillVehicle.isPrefillVehicle = true;
                    prefillVehicle.isSelectedVehicle = false;
                    prefillVehicle.isVehiclePrimarilyKeptAtAHAddress = true;
                    const phAddress = accountHolder.primaryAddress;
                    if (_.isEmpty(prefillVehicle.garagingAddress.value)) {
                        _.set(prefillVehicle, 'garagingAddress.addressLine1.value', phAddress.addressLine1);
                        _.set(prefillVehicle, 'garagingAddress.addressLine2.value', phAddress.addressLine2);
                        _.set(prefillVehicle, 'garagingAddress.city.value', phAddress.city);
                        _.set(prefillVehicle, 'garagingAddress.country.value', phAddress.country);
                        _.set(prefillVehicle, 'garagingAddress.state.value', phAddress.state);
                        _.set(prefillVehicle, 'garagingAddress.postalCode.value', phAddress.postalCode);
                        _.set(prefillVehicle, 'garagingAddress.county.value', phAddress.county);
                        _.set(prefillVehicle, 'garagingAddress.censusBlock.value', phAddress.censusBlock);
                        _.set(prefillVehicle, 'garagingAddress.spatialPoint.value', phAddress.spatialPoint);
                    }
                    // eslint-disable-next-line max-len
                    submissionVM.lobData.personalAuto.coverables.vehicles.pushElement(prefillVehicle);
                }
            });
            const hasPrefill = getPrefillVehicleCount();
            updateHasPrefillVehicles(hasPrefill);
        } else if (hasPrefillVehicles === false && (vehicles.children !== undefined)) {
            if (vehicles.children.length === 1) {
                const vehicle = _.find(vehicles.children, (v) => {
                    return v.isSelectedVehicle.value === false;
                });
                if (vehicle !== null) {
                    vehicle.isOpen = false;
                }
            }
        }
        updateWizardData(submissionVM);
    }, [submissionVM, hasPrefillVehicles, updateWizardData, getPrefillVehicleCount, checkVehicleSelected, viewModelService]);


    const createVehicleVM = useCallback(() => {
        const accountHolder = submissionVM.baseData.accountHolder.value;
        const hasPrefill = getPrefillVehicleCount();
        const existingSelectVehicle = checkSelectedVehicle();
        const vehicleObj = {
            costNew: {},
            garagingAddress: {},
            isOpen: !hasPrefill && !existingSelectVehicle
        };
        // eslint-disable-next-line max-len
        const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto.coverables.vehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);
        const phAddress = accountHolder.primaryAddress;
        if (_.isEmpty(vehicleVM.garagingAddress.value)) {
            _.set(vehicleVM, 'garagingAddress.addressLine1.value', phAddress.addressLine1);
            _.set(vehicleVM, 'garagingAddress.addressLine2.value', phAddress.addressLine2);
            _.set(vehicleVM, 'garagingAddress.city.value', phAddress.city);
            _.set(vehicleVM, 'garagingAddress.country.value', phAddress.country);
            _.set(vehicleVM, 'garagingAddress.state.value', phAddress.state);
            _.set(vehicleVM, 'garagingAddress.postalCode.value', phAddress.postalCode);
            _.set(vehicleVM, 'garagingAddress.county.value', phAddress.county);
            _.set(vehicleVM, 'garagingAddress.censusBlock.value', phAddress.censusBlock);
            _.set(vehicleVM, 'garagingAddress.spatialPoint.value', phAddress.spatialPoint);
        }
        vehicleVM.isPrefillVehicle = false;
        vehicleVM.isSelectedVehicle = false;
        vehicleVM.isOpen = false;
        vehicleVM.isVehiclePrimarilyKeptAtAHAddress = true;
        submissionVM.lobData.personalAuto.coverables.vehicles.pushElement(vehicleVM);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const getSelectedVehicleCount = () => {
        let selectedCount = 0;
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);
        _.forEach(vehicles, (v) => {
            if (v.isSelectedVehicle) {
                selectedCount += 1;
            }
        });
        return selectedCount;
    };

    useEffect(() => {
        async function discountRibbon() {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const disco = await LoadSaveService.getQuoteDiscounts(quoteId);
            _.set(submissionVM, 'baseData.discount', disco);
        }

        if (!isPageInitialised) {
            const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
            const vehicles = _.get(submissionVM, `${vehiclesPath}.value`);
            updateIsLoading(true);
            // Creating Prefill request object
            const prefillInfoLookupRequestDTO = {
                quoteId: submissionVM.quoteID.value
            };

            // Calling prefill service
            // eslint-disable-next-line max-len
            const responsePromise = PrefillLookupService.getPrefillData(prefillInfoLookupRequestDTO);
            responsePromise.then((response) => {
                // GWCI-100693 - Customer Engage: As a customer, I want the correct Membership number to be assigned to me.
                LoadSaveService.setMembershipNumber(submissionVM.quoteID.value).then(_.noop, _.noop);
                if (response.status === 'Error') {
                    ErrorHandlingUtil.processErrorResponse(props, 'serviceError', submissionVM.value);
                }
                // EH-23860 Do Not Return Prefill Data for Bad CVI Score or Out of State DL
                if (response.errorDescription != undefined) {
                    // Display contact us screen if cviscore is 0 or 10
                    if(response.errorDescription.toLowerCase().includes('invalid cvi score')){
                        let cviscore = response.errorDescription.split(": ");
                        //Set cookie
                        cookie.set("cviscore", cviscore[1]);
                        // Using window.location since using props exposed the data for network sniffing
                        window.location = "/quote-and-buy/contact-us";
                } else{
                // ----------    Primary Driver's State Validation    ----------
                    let driverState = response.errorDescription.split(": ");
                    //Set cookie
                    cookie.set("primaryDriverState", driverState[1]);
                    // Using window.location since using props exposed the data for network sniffing
                    window.location = '/quote-and-buy/contact-us';
                }
            }
                // --------------------------------
            else {
                 //EH-21978: Redirecting the user to kickout page if policy has alfa carrier within 12 months
                if (response.hasAlfaInsInPast12MOs !== undefined && response.hasAlfaInsInPast12MOs === true) {
                    if (submissionVM.baseData.agentId.value !== undefined) {
                        location.replace(getConfigValue('ALFA_KICKOUT_AGENT_PAGE_URL') + '?agentId=' + submissionVM.baseData.agentId.value);
                    } else {
                        location.replace(getConfigValue('ALFA_KICKOUT_PAGE_URL'));
                    }
                }

                if (response.priorPolicies !== undefined && response.priorPolicies.length > 0) {
                    window.scrollTo(0, 0);
                    if (_.find(response.priorPolicies, (policy) => {
                        if (policy.status === undefined) { return false; }
                        return (policy.status.toUpperCase() === 'IN-EFFECT' && policy.carrier.toUpperCase().substring(0, 4) === 'ALFA');
                    })) {
                        if (submissionVM.baseData.agentId.value !== undefined) {
                            location.replace(getConfigValue('ALFA_KICKOUT_AGENT_PAGE_URL') + '?agentId=' + submissionVM.baseData.agentId.value);
                        } else {
                            location.replace(getConfigValue('ALFA_KICKOUT_PAGE_URL'));
                        }
                    }
                    const inforceCurrentCarrier = _.find(response.priorPolicies, (policy) => {
                        if (policy.status === undefined) { return false; }
                        return policy.status.toUpperCase() === 'IN-EFFECT';
                    });
                    updateHasInforceCurrentCarrier(inforceCurrentCarrier !== undefined);
                }
                if (typeof response === 'object' && response.vehicles.length !== 0) {
                    addVehicles(response);
                }
                const existingNewVehicle = _.find(vehicles, (v) => {
                    return !v.isSelectedVehicle && !v.isPrefillVehicle;
                });

                if (_.isEmpty(vehicles) || _.isEmpty(existingNewVehicle)) {
                    createVehicleVM();
                }
                updateIsLoading(false);
            }
            }, (error) => {
                updateIsLoading(false);
                console.log(error);
                callErrorHandler(error, true);
            });

            discountRibbon();

            const hasPrefill = getPrefillVehicleCount();
            updateHasPrefillVehicles(hasPrefill);

            const selectedCount = getSelectedVehicleCount();
            updateSelectedVehicleCount(selectedCount);
            updateIsPageInitialised(true);
        }
        // eslint-disable-next-line max-len
    }, [createVehicleVM, isPageInitialised, submissionVM, updateWizardData, updateSelectedVehicleCount, updateHasPrefillVehicles, getPrefillVehicleCount, getSelectedVehicleCount, addVehicles]);

    const closeAllTiles = useCallback(() => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles.children';
        const vehicles = _.get(submissionVM, vehiclesPath);
        _.forEach(vehicles, (vehicle, index) => {
            _.set(submissionVM, `lobData.personalAuto.coverables.vehicles.children.${index}.value.isOpen`, false);
        });
        updateWizardData(submissionVM);

    }, [submissionVM, updateWizardData]);

    const addVehicleVM = useCallback(
        () => {
            closeAllTiles();
            updateWizardData(submissionVM);
            const currentSelectedVehicle = getSelectedVehicleCount();
            updateSelectedVehicleCount(currentSelectedVehicle);
            const currentPrefillCount = getPrefillVehicleCount();
            updateHasPrefillVehicles(currentPrefillCount);
            updateOpenIndex(undefined);
            if (nextErrorMessage && (currentSelectedVehicle > 0)) {
                updateNextErrorMessage(false);
                updateShowError(false);
            }
            setTimeout(() => {
                setDummyFlag(dummyFlag ? undefined : 1);
            }, 2);
        }, [closeAllTiles, updateWizardData, submissionVM, getSelectedVehicleCount, getPrefillVehicleCount, dummyFlag]
    );
    const editVehicle = useCallback(
        (path, vehicleIndex, isOpen) => {
            const vehiclePath = path.replace(/\.children\.(\d+)/, '.children[$1]');
            // updateShowError(false);
            const vehicle = _.get(submissionVM, vehiclePath);
            const vehicleCheck = submissionVM.lobData.personalAuto.coverables.vehicles.children[vehicleIndex];
            const address = _.cloneDeep(vehicleCheck);
            const vin = vehicleCheck.vin.value;
            const loan = vehicleCheck.leaseOrRent.value;
            const VehicleAtPrimary = vehicleCheck.isVehiclePrimarilyKeptAtAHAddress.value;

            const addressDTO = {
                addressLine1: vehicleCheck.garagingAddress.addressLine1.value,
                addressLine2: vehicleCheck.garagingAddress.addressLine2.value,
                city: vehicleCheck.garagingAddress.city.value,
                state: vehicleCheck.garagingAddress.state.value,
                postalCode: vehicleCheck.garagingAddress.postalCode.value,
                county: vehicleCheck.garagingAddress.county.value,
                country: vehicleCheck.garagingAddress.country.value
            };
            updateVinNumber(vin);
            updateLease(loan);
            updateIsVehiclePrimarilyKeptAtAddress(VehicleAtPrimary);
            updateGarageAddress(addressDTO);
            if (vehicleCheck.aspects.subtreeValid) {
                if (!isOpen) {
                    updateOpenIndex(vehicleIndex);
                } else {
                    updateOpenIndex(undefined);
                }
            }
            _.set(vehicle, 'isOpen', false);
            updateWizardData(submissionVM);
        }, [submissionVM, updateWizardData]
    );

    const clearVehicle = useCallback(
        (currentPath) => {
            const vehiclePath = currentPath.replace(/\.children\.(\d+)/, '.children[$1].value');
            const vehicle = _.get(submissionVM, vehiclePath);
            const primaryAddress = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
            _.set(vehicle, 'isVehiclePrimarilyKeptAtAHAddress', true);

            _.set(vehicle, 'leaseOrRent', false);
            _.set(vehicle, 'garagingAddress', primaryAddress.value);

            if (!vehicle.isPrefillVehicle) {
                _.set(vehicle, 'vin', undefined);
                _.set(vehicle, 'make', undefined);
                _.set(vehicle, 'model', undefined);
                _.set(vehicle, 'vehicleTrim', undefined);
                _.set(vehicle, 'bodyType', undefined);
                _.set(vehicle, 'vehicleType', undefined);
            }
        }, [submissionVM]
    );

    const removeCancelVehicle = useCallback(
        (currentPath, vehicleData, toClearOnly, fromCancel) => {
            const vehicleListPath = 'lobData.personalAuto.coverables.vehicles.value';
            let vehiclePath = '';
            if (currentPath !== '') {
                vehiclePath = currentPath.replace(/\.children\.(\d+)/, '.children[$1].value');
            }
            const vehicle = currentPath !== '' ? _.get(submissionVM, vehiclePath) : vehicleData;
            const vehicleList = _.get(submissionVM, vehicleListPath);

            if (toClearOnly || fromCancel === 'fromCancel') {
                closeAllTiles();
                if (currentPath !== '') {
                    clearVehicle(currentPath);

                }
                //_.set(vehicle, 'isOpen', true);
                updateWizardData(submissionVM);
            } else {
                // Creating remove vehicle request object
                const addVehicleRequestDTO = {
                    quoteId: _.get(submissionVM, 'quoteID.value', {}),
                    vehicle: vehicle
                };

                // Calling remove vehicle service
                const responsePromise = AddCoverablesService.removeVehicle(addVehicleRequestDTO);
                responsePromise.then((response) => {
                    console.log(response);
                    _.set(vehicle, 'isSelectedVehicle', false);
                    _.set(vehicle, 'isOpen', false);

                    if (!vehicle.isPrefillVehicle) {
                        const newVehicleList = _.remove(vehicleList,
                            (vehicleFromList) => _.isEqual(vehicleFromList, vehicle));
                        _.set(submissionVM, newVehicleList, vehicleListPath);
                    }
                    updateWizardData(submissionVM);
                    const currentSelectedVehicle = getSelectedVehicleCount();
                    updateSelectedVehicleCount(currentSelectedVehicle);
                    const hasPrefill = getPrefillVehicleCount();
                    updateHasPrefillVehicles(hasPrefill);
                }, (error) => {
                    callErrorHandler(error, true);
                });
            }
            // eslint-disable-next-line max-len
        }, [submissionVM, updateWizardData, clearVehicle, getSelectedVehicleCount, getPrefillVehicleCount, closeAllTiles]
    );

    const fieldIsUnspecified = (fieldInQuestion) => {
        let result = false;
        if (fieldInQuestion === undefined || fieldInQuestion === null || fieldInQuestion === '') {
            result = true;
        }
        return result;
    };

    const checkVehicleValid = useCallback((vehicleVM) => {
        const vehicle = vehicleVM.value;
        if (!_.isEmpty(vehicle.vehicleType) || !_.isEmpty(vehicle.year) || !_.isEmpty(vehicle.make) || !_.isEmpty(vehicle.model)) {
            if (!vehicleVM.aspects.subtreeValid) {
                return false;
            }
            if (!vehicleVM.garagingAddress.aspects.subtreeValid) {
                return false;
            }
            if (fieldIsUnspecified(vehicle.garagingAddress.addressLine1) || fieldIsUnspecified(vehicle.garagingAddress.city)
                || fieldIsUnspecified(vehicle.garagingAddress.state) || fieldIsUnspecified(vehicle.garagingAddress.postalCode)
                || fieldIsUnspecified(vehicle.garagingAddress.county)) {
                return false;
            }
            return true;
        }
        return true;
    }, []);

    const closeOtherTiles = useCallback((path, vehicleIndex) => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles.children';
        const vehicles = _.get(submissionVM, vehiclesPath);
        const vehiclePath = path.replace(/\.children\.(\d+)/, '.children[$1]');
        const vehicleCheck = submissionVM.lobData.personalAuto.coverables.vehicles.children[vehicleIndex];
        // updateShowError(false);
        let isAllVehiclesValid = true;
        _.forEach(vehicles, (v) => {
            if (!checkVehicleValid(v)) {
                isAllVehiclesValid = false;
            }
        });
        const vehicle = _.get(submissionVM, vehiclePath);
        if (vehicle.isOpen !== undefined && vehicle.isOpen) {
            if (!checkVehicleValid(vehicleCheck)) {
                updateDirtyFlag(true);
                updateShowError(true);
            } else {
                _.set(vehicle, 'isOpen', false);
                updateWizardData(submissionVM);
                updateShowError(false);
            }
            // updateShowError(true);
        } else if (isAllVehiclesValid) {
            _.forEach(vehicles, (v, index) => {
                if (index !== vehicleIndex) {
                    _.set(submissionVM, `lobData.personalAuto.coverables.vehicles.children.${index}.value.isOpen`, false);
                } else {
                    _.set(submissionVM, `lobData.personalAuto.coverables.vehicles.children.${index}.value.isOpen`, true);
                }
            });
            const addressObj = {
                addressLine1: vehicleCheck.garagingAddress.addressLine1.value,
                addressLine2: vehicleCheck.garagingAddress.addressLine2.value,
                city: vehicleCheck.garagingAddress.city.value,
                state: vehicleCheck.garagingAddress.state.value,
                postalCode: vehicleCheck.garagingAddress.postalCode.value,
                county: vehicleCheck.garagingAddress.county.value,
                country: vehicleCheck.garagingAddress.country.value
            };
            updateVinNumber(vehicleCheck.vin.value);
            updateLease(vehicleCheck.leaseOrRent.value);
            updateIsVehiclePrimarilyKeptAtAddress(vehicleCheck.isVehiclePrimarilyKeptAtAHAddress.value);
            updateGarageAddress(addressObj);
            updateDirtyFlag(false);
            updateShowError(false);
            updateWizardData(submissionVM);
        }
    }, [submissionVM, checkVehicleValid, updateWizardData, updateShowError]);

    const renderVehicleAccordionHeader = useCallback(
        (vehicleName, pathOfVehicleToRender, vehicleIndex, isPrefill, isSelected, isVehicleOpen) => {
            return () => (
                <div className={styles.accordionHeaderContainer}>
                    <VehicleHeaderComponent
                        vehicleName={vehicleName}
                        path={pathOfVehicleToRender}
                        vehicleIndex={vehicleIndex}
                        isAccordionOpen={isVehicleOpen}
                        isPrefill={isPrefill}
                        isSelected={isSelected}
                        onRemoveVehicle={removeCancelVehicle}
                        onEditVehicle={editVehicle}
                        closeOtherTiles={closeOtherTiles}

                    />
                </div>
            );
        }, [removeCancelVehicle, editVehicle, closeOtherTiles]
    );

    const getCollapseClass = (index) => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicle = submissionVM.lobData.personalAuto.coverables.vehicles.children[index];
        if (vehicle.value.isOpen) {
            return 'blockCollapse';
        }
        if (!vehicle.value.isOpen) {
            return 'allowCollapse';
        }
        return 'allowCollapse';
    };

    const checkOpenVehicle = useCallback(() => {
        const vehicle = _.find(submissionVM.lobData.personalAuto.coverables.vehicles.children, (v) => {
            return v.value.isOpen === true;
        });
        if (vehicle !== null && vehicle !== undefined) {
            return true;
        }
        return false;
    }, []);

    const callErrorHandler = useCallback((error, captchaOnly) => {
        if (!_.isUndefined(captchaOnly) && captchaOnly && 
                !_.isUndefined(error.baseError) && error.baseError.includes('Invalid Recaptcha')) {
                ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value);
        } else {
            ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value);
        }
    }, []);



    const generateOverrides = useCallback(() => {
        const vehiclePath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', []);
        const vehiclesVM = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles', []);
        const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value', {});
        const quoteId = _.get(submissionVM, 'quoteID.value', {});


        const overrides = vehicles.map((vehicle, index) => {
            let driverTitle = translator(messages.paVehicleTitleNumber);

            if (vehicles.length > 1 && index !== 0) {
                driverTitle = `${translator(messages.paVehicleTitleNumber)} ${index + 1}`;
            }
            const overridePath = `${vehiclePath}.children[${index}]`;

            const vehicleName = (vehicle.year !== undefined && vehicle.make !== undefined && vehicle.model !== undefined) ? ''.concat(vehicle.year, ' ', vehicle.make, ' ', vehicle.model) : '';
            return {
                [`vehicleComponent${index}`]: {
                    accountHolder: accountHolder,
                    quoteId: quoteId,
                    vehicles: vehicles,
                    addVehicleVM: addVehicleVM,
                    createVehicle: createVehicleVM,
                    removeVehicle: removeCancelVehicle,
                    garrageAddress: garrageAddress,
                    vinNumber: vinNumber,
                    lease: lease,
                    isVehiclePrimarilyKeptAtAddress: isVehiclePrimarilyKeptAtAddress,
                    showErrors: showError,
                    callErrorHandler: callErrorHandler
                },
                [`vehicleSelectedAccordionDiv${index}`]: {
                    className: !vehicle.isOpen && checkOpenVehicle() ? 'hideAccordion' : 'vehicleAccordionContainer',
                },
                [`vehiclePrefillAccordionDiv${index}`]: {
                    className: !vehicle.isOpen && checkOpenVehicle() ? 'hideAccordion' : 'vehicleAccordionContainer',
                },
                [`vehicleNewAccordionDiv${index}`]: {
                    className: !vehicle.isOpen && checkOpenVehicle() ? 'hideAccordion' : 'vehicleAccordionContainer',
                },
                [`vehiclePrefillAccordionCard${index}`]: {
                    header: renderVehicleAccordionHeader(
                        vehicleName,
                        `${overridePath}.value`,
                        index,
                        true,
                        false,
                        vehicle.isOpen
                    ),
                    visible: vehicle.isPrefillVehicle && !vehicle.isSelectedVehicle,
                    collapseClassName: getCollapseClass(index),
                    //errorState: !checkVehicleValid(vehiclesVM.children[index])
                },
                [`newVehicleAccordionCard${index}`]: {
                    header: renderVehicleAccordionHeader(
                        'Add Vehicle',
                        `${overridePath}.value`,
                        index,
                        false,
                        false,
                        vehicle.isOpen
                    ),
                    visible: !vehicle.isPrefillVehicle && !vehicle.isSelectedVehicle,
                    collapseClassName: getCollapseClass(index),
                    //errorState: !checkVehicleValid(vehiclesVM.children[index])
                },

                [`selectedVehicleAccordionCard${index}`]: {
                    header: renderVehicleAccordionHeader(
                        vehicleName,
                        `${overridePath}.value`,
                        index,
                        false,
                        true,
                        vehicle.isOpen,
                        editVehicle,
                        removeCancelVehicle
                    ),
                    visible: vehicle.isSelectedVehicle,
                    collapseClassName: getCollapseClass(index),
                    //errorState: !checkVehicleValid(vehiclesVM.children[index])
                },
                [`paDeleteVehicle${index}`]: {
                    visible: vehicles.length > 1
                },
                [`paVehicleTitle${index}`]: {
                    value: driverTitle
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM, translator, addVehicleVM, createVehicleVM, removeCancelVehicle, showError, callErrorHandler, checkOpenVehicle, renderVehicleAccordionHeader, getCollapseClass, editVehicle, garrageAddress]);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        dataLayer.push({ 'event': 'gtm.click', 'gtm.elementId': 'qb_next' });
        const selectedVehicle = checkSelectedVehicle();
        if (!selectedVehicle) {
            updateNextErrorMessage(true);
            updateShowError(true);
            return false;
        }

        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, vehiclesPath);
        const validVehicles = _.find(vehicles.children, (v) => {
            return !(v.isSelectedVehicle.value === false && v.isPrefillVehicle.value === false);
        });
        let invalidManualVehicle = false;
        vehicles.children.map((object) => {
            if (!object.isSelectedVehicle.value && !object.isPrefillVehicle.value) {
                if (!_.isUndefined(object.year.value) || !_.isUndefined(object.make.value) || !_.isUndefined(object.model.value) || !_.isUndefined(object.vehicleType.value)) {
                    if (_.isUndefined(object.year.value) || _.isUndefined(object.make.value) || _.isUndefined(object.model.value) || _.isUndefined(object.vehicleType.value)) {
                        invalidManualVehicle = true;
                    }
                }
            }
        });
        if (!validVehicles.aspects.subtreeValid || invalidManualVehicle) {
            updateShowError(true);
            return false;
        }
        updateShowError(false);
        updateNextErrorMessage(false);

        const vehLen = vehicles.length - 1;
        const vehiclesData = vehicles.value;
        for (let j = vehLen; j >= 0; j--) {
            const vehicle = vehiclesData[j];
            if (!vehicle.isSelectedVehicle) {
                const newVehicleList = _.remove(vehiclesData,
                    (vehicleFromList) => _.isEqual(vehicleFromList, vehicle));
                _.set(submissionVM, newVehicleList, vehiclesData);
            }
        }
        updateWizardData(submissionVM);
        const quoteId = _.get(submissionVM, 'quoteID.value');
        const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
        _.set(submissionVM, 'baseData.discount', discounts);
        return submissionVM;
    }, [LoadSaveService, checkSelectedVehicle, submissionVM, updateWizardData]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        prefillContainer: {
            visible: hasPrefillVehicles
        },
        selectedContainer: {
            visible: (selectedVehicleCount > 0)
        },
        paVehiclePageNextError: {
            visible: nextErrorMessage
        },
        vehiclePageNextDiv: {
            visible: hasPrefillVehicles && selectedVehicleCount === 0 && hasInforceCurrentCarrier
        },
        ...generateOverrides()
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            removeVehicle: removeCancelVehicle,
            createVehicle: createVehicleVM,
            addVehicleVM: addVehicleVM,
            onValidate
        },
        resolveComponentMap: {
            tooltipcomponent: YourVehicleTooltip
        }
    };

    const isSubmissionQuoted = useCallback(() => {
        return _.get(submissionVM.value, 'baseData.periodStatus') === 'Quoted';
    }, [submissionVM]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    useEffect(() => {
        registerInitialComponentValidation(isSubmissionQuoted);
    }, [isSubmissionQuoted, registerInitialComponentValidation]);

    if (!isPageInitialised) {
        return null;
    }

    const clearNotSelectedVehicles = () => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, vehiclesPath);
        const vehLen = vehicles.length - 1;
        const vehiclesData = vehicles.value;
        for (let i = vehLen; i >= 0; i--) {
            const vehicle = vehiclesData[i];
            if (!vehicle.isSelectedVehicle) {
                const newVehicleList = _.remove(vehiclesData,
                    (vehicleFromList) => _.isEqual(vehicleFromList, vehicle));
                _.set(submissionVM, newVehicleList, vehiclesData);
            }
        }
        updateWizardData(submissionVM);
    };

    const skipVehicles = () => {
        const driver = submissionVM.value.lobData.personalAuto.coverables.drivers[0];

        if (driver && driver.isSelectedDriver) {
            clearNotSelectedVehicles();
        }
        return driver && driver.isSelectedDriver;
    };

    const onPrevious = () => {
        const selectedVehicle = checkSelectedVehicle();
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const vehicles = _.get(submissionVM, vehiclesPath);
        const validVehicles = _.find(vehicles.children, (v) => {
            return !(v.isSelectedVehicle.value === false && v.isPrefillVehicle.value === false);
        });
        let invalidManualVehicle = false;
        vehicles.children.map((object) => {
            if (!object.isSelectedVehicle.value && !object.isPrefillVehicle.value) {
                if (!_.isUndefined(object.year.value) || !_.isUndefined(object.make.value) || !_.isUndefined(object.model.value) || !_.isUndefined(object.vehicleType.value)) {
                    if (_.isUndefined(object.year.value) || _.isUndefined(object.make.value) || _.isUndefined(object.model.value) || _.isUndefined(object.vehicleType.value)) {
                        invalidManualVehicle = true;
                    }
                }
                if (!object.garagingAddress.addressLine1.value || !object.garagingAddress.city.value || !object.garagingAddress.county.value || !object.garagingAddress.state.value || !object.garagingAddress.postalCode.value) {
                    invalidManualVehicle = true;
                }
            }
            if (!object.isSelectedVehicle.value && object.isPrefillVehicle.value) {
                if (!object.garagingAddress.addressLine1.value || !object.garagingAddress.city.value || !object.garagingAddress.county.value || !object.garagingAddress.state.value || !object.garagingAddress.postalCode.value) {
                    invalidManualVehicle = true;
                }
            }
        });

        if (!(validVehicles && !validVehicles.aspects.subtreeValid || invalidManualVehicle) && selectedVehicle) {
            clearNotSelectedVehicles();
        }
        return submissionVM;
    };

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <WizardPage onNext={onNext} skipWhen={skipVehicles} onPrevious={onPrevious} showCancel={false} previousLabel="Back" nextLabel="Next : Drivers">
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default withRouter(VehiclesPage);
